import React, { useMemo } from 'react';
import { Slider as AntSlider , ConfigProvider , theme } from 'antd';
import './style.css';

const CustomSlider = ({ defaultValue, min, max, onChange, step, value , name }) => {
  const generateMarks = (step, max) => {
    const marks = {};
    for (let i = 0; i <= max; i += step) {
      if (i % step === 0) {
        marks[i] = {
          label: <strong style={{ display: 'none' }}>{`${i.toLocaleString()}`}</strong>,
        };
      }
    }
    return marks;
  };

  const totalMarks = useMemo(() => generateMarks(step, max), [step, max]);

  return (
    <div className="" style={{ width: "80%" }}>
      <ConfigProvider
       theme={{
        components: {
          Slider: {
            dotSize : 15,
            dotActiveBorderColor: '#4b8cdc',
            dotBorderColor : 'gray',
            railSize	: 8,
            trackHoverBg : '#4b8cdc',
            trackBg : "#4b8cdc",
            handleSize: 25,
            handleSizeHover : 25
          },
        },
    }}
  >
 <AntSlider
        marks={totalMarks && totalMarks}
        className="custom-slider"
        defaultValue={defaultValue}
        min={min}
        max={max}
        keyboard = {true}
        value={value}
        onChange={onChange}
        tooltip={{ formatter: (value) =>  name == 'employeesInOrganization' ? `${value}` : `$${value}` , color : '#370173' ,}} // Show only the number
      />

  </ConfigProvider>
     
    </div>
  );
};

export default CustomSlider;

import React, { useEffect, useState } from "react";
import CustomSlider from "../../Components/Slider";
import "./style.css";
import Navbar from "../Navbar";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../baseUrl";
import Loader from "../Loader";
import Swal from "sweetalert2";
import Footer from "../Footer";
import Banner from "./components/Banner";
import { InputNumberProps, InputNumber } from "antd";

const FormComponent = () => {
  const Navigate = useNavigate();
  const [isFirstStepCompleted, setIsFirstStepCompleted] = useState(false);
  const [isSecondStepCompleted, setIsSecondStepCompleted] = useState(false);
  const [isThirdStepCompleted, setIsThirdStepCompleted] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [shouldReload, setShouldReload] = useState(false);
  useEffect(() => {}, [shouldReload]);
  const [formData, setFormData] = useState({
    bestDescribes: "",
    // employeesInOrganization: 0,
    // avgMonthlySpendOnEnergy: 0,
    avgMonthlyCreditCardVolume: 0,
    // avgMonthlySpendOnWater: 0,
    paymentMethod: "",
    // vendorPayments: 0,
    // shippingProvider: '',
    // shippingSpend: 0,
    // telecomSpend: 0,
    // wasteRemovalSpend: 0,
    // itHardwareSpend: 0,
    bankFees: 0,
    // propertyPurchased: 'No',
    // usesPEO: 'No',
    email: "",
  });
  console.log(formData);

  const handleSliderChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const zeroValueFields = Object.keys(formData).filter(
      (key) =>
        key !== "avgMonthlyCreditCardVolume" &&
        typeof formData[key] === "number" &&
        formData[key] === 0
    );

    const missingFields = Object.keys(formData).filter(
      (key) => formData[key] === ""
    );
    if (zeroValueFields.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fields with zero values: " + zeroValueFields.join(", "),
      });
      return null;
    } else if (missingFields.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Missing fields: " + missingFields.join(", "),
      });
      return null;
    } else {
    }

    try {
      setIsLoading(true);
      const response = await fetch(BASE_URL + "user/addSavingEstimator", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setShouldReload(!shouldReload);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Thank you!",
          text: "The average savings is typically between 10-20% of your monthly fees.  Our Audit experts will be reaching out to you within week to review your Audit Analysis.",
          showConfirmButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.setItem("savingEstimatorForm", formData.email);
            localStorage.clear();
            // Navigate("/thankyou" , {replace :  true})
            window.location.href = "home.html";
          }
        });
      }

      if (response.status == 400) {
        throw new Error("Form with this email already exists");
      }

      if (!response.ok) {
        throw new Error("Failed to submit form");
      }

      const data = await response.json();
      console.log("Record added successfully:", data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message,
      });
      console.error("Error adding record:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkFirstStep();
    checkSecondStep();
    checkThirdStep();
  }, [formData]);

  const checkFirstStep = () => {
    const { employeesInOrganization, bestDescribes } = formData;
    if (
      employeesInOrganization > 0 &&
      bestDescribes &&
      formData.avgMonthlySpendOnEnergy > 0 &&
      formData.avgMonthlySpendOnWater > 0
    ) {
      setIsFirstStepCompleted(true);
    } else {
      setIsFirstStepCompleted(false);
    }
  };

  const checkSecondStep = () => {
    if (
      formData.vendorPayments > 0 &&
      formData.paymentMethod &&
      formData.shippingSpend > 0 &&
      formData.shippingProvider &&
      formData.telecomSpend > 0 &&
      formData.wasteRemovalSpend > 0 &&
      formData.itHardwareSpend > 0 &&
      formData.bankFees > 0 &&
      formData.propertyPurchased &&
      formData.usesPEO
    ) {
      console.log("inside");
      setIsSecondStepCompleted(true);
    } else {
      setIsSecondStepCompleted(false);
    }
  };

  const checkThirdStep = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailPattern.test(formData.email)) {
      setIsThirdStepCompleted(true);
    } else {
      setIsThirdStepCompleted(false);
    }
  };

  const handleFirstStepCompletion = () => {
    // Close the first accordion and open the second one
    setIsFirstStepCompleted(true);
    document.getElementById("collapseOne").classList.remove("show");
    document.getElementById("collapseTwo").classList.add("show");
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Navbar />
      <Banner />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: "40px",
        }}
      >
        <div style={{ border: "1px", borderRadius: "30px", width: "1000px" }}>
          <form onSubmit={handleSubmit}>
            <div className="container" style={{ backgroundColor: "#E9EFFD" }}>
              <div className="form-group mb-4 py-2">
                <label className="mb-4" style={{ fontSize: "1.2rem" }}>
                  Which Best describes your industry?
                </label>
                <input
                  name="bestDescribes"
                  type="text"
                  placeholder="Manufacturing, Technology etc"
                  value={formData.bestDescribes}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>

            <div
              className="container py-2"
              style={{ backgroundColor: "#E9EFFD" }}
            >
              <div className="form-group mb-4">
                <label className="mb-2" style={{ fontSize: "1.2rem" }}>
                  Average Monthly credit card volume
                </label>
                <label className="mb-4" style={{ fontSize: "1.2rem" }}>
                  Keep volume level at $0, if your organization does not accept
                  credit cards as a form of payment.
                </label>
                <div className="d-flex flex-wrap justify-content-between align-items-center gap-2">
                  <CustomSlider
                    step={50000}
                    onChange={(value) =>
                      handleSliderChange("avgMonthlyCreditCardVolume", value)
                    }
                    min={0}
                    max={500000}
                    defaultValue={0}
                  />
                  <div style={{ width: "15%" }}>
                    <input
                      name="avgMonthlyCreditCardVolume"
                      value={formData.avgMonthlyCreditCardVolume}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="container" style={{ backgroundColor: "#E9EFFD" }}>
              <div className="form-group mb-4 py-2">
                <label className="mb-4" style={{ fontSize: "1.2rem" }}>
                  How do you typically pay vendors?
                </label>
                <input
                  name="paymentMethod"
                  type="text"
                  placeholder="Check, EFT/Wire Tranfer etc"
                  value={formData.paymentMethod}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>

            <div
              className="container py-2 mb-4"
              style={{ backgroundColor: "#E9EFFD" }}
            >
              <div className="form-group mb-4">
                <label className="mb-4" style={{ fontSize: "1.2rem" }}>
                  Average Monthly Bank Fees
                </label>
                <div className="d-flex flex-wrap justify-content-between align-items-center gap-2">
                  <CustomSlider
                    step={500}
                    onChange={(value) => handleSliderChange("bankFees", value)}
                    min={0}
                    max={5000}
                    defaultValue={0}
                  />
                  <div style={{ width: "15%" }}>
                    <input name="bankFees" value={formData.bankFees} readOnly />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="container py-2 mb-4"
              style={{ backgroundColor: "#E9EFFD" }}
            >
              <div className="form-group mb-4">
                <label className="mb-4" style={{ fontSize: "1.2rem" }}>
                  Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  placeholder="john@example.com"
                  name="email"
                  value={formData.email}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <button
                  onClick={() => handleSubmit}
                  type="submit"
                  className="btn btn-primary"
                >
                  Submit
                </button>
              </div>
            </div>

            {/* <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne" style={{display:'flex' , justifyContent : 'space-between'}}>
              <button className="accordion-button d-flex justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <span>Saving Estimator</span>
                {isFirstStepCompleted && <span style={{ marginLeft: '2%', fontSize: '32px', color: 'green' }} className="checkmark">&#10003;</span>}
              </button>

              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <div style={{ height: '7%', width: '100%', padding: '5px' }}>
                   

                    <div className="container py-2" style={{ backgroundColor: '#E9EFFD' }}>
                      <div className="form-group mb-4">
                        <label className="" style={{ fontSize: '1.2rem' }}>How many employees are in your organization?</label>
                        <div className='d-flex flex-wrap justify-content-between align-items-center gap-2' >
                          <CustomSlider step={5000} 
                            onChange={(value) => handleSliderChange('employeesInOrganization', value)}

                          min={0} max={30000} defaultValue={0} name = 'employeesInOrganization' />
                          <div style={{width:"15%"}}> 
                          <input name="employeesInOrganization" value={formData.employeesInOrganization} readOnly />
                          </div>
                        </div>
                      </div>
                    </div>

                   <div className="container py-2" style={{ backgroundColor: '#E9EFFD' }}>
                      <div className="form-group mb-4">
                        <label className="mb-4" style={{ fontSize: '1.2rem' }}>Average Monthly spend on Energy</label>
                        <label>(Electricity, Natural gas, and Other fuels)</label>
                        <div className='d-flex flex-wrap justify-content-between align-items-center gap-2' >
                          <CustomSlider step={10000} 
                          onChange={(value) => handleSliderChange('avgMonthlySpendOnEnergy', value)} 
                          min={0} max={100000} defaultValue={0} />
                          <div style={{width:"15%"}}> 
                          <input name="avgMonthlySpendOnEnergy" value={formData.avgMonthlySpendOnEnergy} readOnly />
                          </div>
                        </div>
                      </div>
                    </div>

                     

                    <div className="container py-2" style={{ backgroundColor: '#E9EFFD' }}>
                      <div className="form-group mb-4">
                        <label className="mb-4" style={{ fontSize: '1.2rem' }}>Average Monthly spend on water/sewer?</label>
                        <div className='d-flex flex-wrap justify-content-between align-items-center gap-2' >
                          <CustomSlider step={10000} onChange={(value) => handleSliderChange('avgMonthlySpendOnWater', value)} min={0} max={100000} defaultValue={0} />
                          <div style={{width:"15%"}}> 
                          <input name="avgMonthlySpendOnWater" value={formData.avgMonthlySpendOnWater} readOnly />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Paying Vendor
                  {isSecondStepCompleted && <span style={{ marginLeft: '2%', fontSize: '32px', color: 'green' }} className="checkmark">&#10003;</span>}

                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <div style={{ height: '7%', width: '100%', padding: '5px' }}>
                   

                    <div className="container py-2 mb-4" style={{ backgroundColor: '#E9EFFD' }}>
                      <div className="form-group mb-4">
                        <label className="mb-2" style={{ fontSize: '1.2rem' }}>Average Total Monthly Vendor Payments</label>
                        <label className="mb-4" style={{ fontSize: '1.2rem' }}>*If spend is {">"} $25M amount must be input manually.  </label>
                        <div className='d-flex flex-wrap justify-content-between align-items-center gap-2' >
                          <CustomSlider step={5000000} value = {formData.vendorPayments} onChange={(value) => handleSliderChange('vendorPayments', value)} min={0} max={25000000} defaultValue={0} />
                          <div style={{width:"15%"}}> 
                          <input name="vendorPayments" value={formData.vendorPayments} onChange={(e)=>handleInputChange(e)} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="container py-2 mb-4" style={{ backgroundColor: '#E9EFFD' }}>
                      <div className="form-group mb-4" style={{ flexDirection: 'column', display: 'flex' }}>
                        <label className="mb-4" style={{ fontSize: '1.2rem' }}>Who is your primary parcel shipping provider?</label>
                        <input name='shippingProvider' type= 'text' placeholder='UPS ,FedEx etc' value = {formData.shippingProvider} onChange={(e)=>handleInputChange(e)} />
                      </div>
                    </div>

                    <div className="container py-2 mb-4" style={{ backgroundColor: '#E9EFFD' }}>
                      <div className="form-group mb-4">
                        <label className="mb-2" style={{ fontSize: '1.2rem' }}>Average Monthly Shipping Spend?</label>
                        <label className="mb-4" style={{ fontSize: '1.2rem' }}>All Carriers</label>

                        <div className='d-flex flex-wrap justify-content-between align-items-center gap-2' >
                          <CustomSlider 
                            step={10000} 
                            // marks={marksShippingSpend} 
                            onChange={(value) => handleSliderChange('shippingSpend', value)} 
                            min={0} 
                            max={50000} 
                            defaultValue={0} 
                          />
                           <div style={{width:"15%"}}> 
                          <input name="shippingSpend" value={formData.shippingSpend} readOnly />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="container py-2 mb-4" style={{ backgroundColor: '#E9EFFD' }}>
                      <div className="form-group mb-4">
                        <label className="mb-4" style={{ fontSize: '1.2rem' }}>Average Monthly spend on Telecommunication & Wireless</label>
                        <div className='d-flex flex-wrap justify-content-between align-items-center gap-2' >
                          <CustomSlider step={10000} onChange={(value) => handleSliderChange('telecomSpend', value)} min={0} max={100000} defaultValue={0} />
                          <div style={{width:"15%"}}> 
                          <input name="telecomSpend" value={formData.telecomSpend} readOnly />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="container py-2 mb-4" style={{ backgroundColor: '#E9EFFD' }}>
                      <div className="form-group mb-4">
                        <label className="mb-4" style={{ fontSize: '1.2rem' }}>Average Monthly Waste Removal Spend</label>
                        <div className='d-flex flex-wrap justify-content-between align-items-center gap-2' >
                          <CustomSlider step={5000} onChange={(value) => handleSliderChange('wasteRemovalSpend', value)} min={0} max={50000} defaultValue={0} />
                          <div style={{width:"15%"}}> 
                          <input name="wasteRemovalSpend" value={formData.wasteRemovalSpend} readOnly />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="container py-2 mb-4" style={{ backgroundColor: '#E9EFFD' }}>
                      <div className="form-group mb-4">
                        <label className="mb-4" style={{ fontSize: '1.2rem' }}>Average Monthly IT-Hardware Spend</label>
                        <div className='d-flex flex-wrap justify-content-between align-items-center gap-2' >
                          <CustomSlider step={10000} onChange={(value) => handleSliderChange('itHardwareSpend', value)} min={0} max={100000} defaultValue={0} />
                          <div style={{width:"15%"}}> 
                          <input name="itHardwareSpend" value={formData.itHardwareSpend} readOnly />
                          </div>
                        </div>
                      </div>
                    </div>

                   
                     <div className="container py-2 mb-4" style={{ backgroundColor: '#E9EFFD' }}>
                      <div className="form-group mb-4">
                        <label className="mb-4" style={{ fontSize: '1.2rem' }}>Have you built, purchased, or refurbished any commercial property within the last 10 years?</label>

                        <select 
                          className="form-control" 
                          id="propertyPurchased" 
                          name="propertyPurchased"
                          value={formData.propertyPurchased} 
                          onChange={(e)=>handleInputChange(e)}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                    </div> 

                    <div className="container py-2 mb-4" style={{ backgroundColor: '#E9EFFD' }}>
                      <div className="form-group mb-4">
                        <label className="mb-4" style={{ fontSize: '1.2rem' }}>Do your organization use a PEO as an HR solution?</label>
                        <select 
                          className="form-control" 
                          id="usesPEO" 
                          name="usesPEO"
                          value={formData.usesPEO} 
                          onChange={(e)=>handleInputChange(e)}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                    </div> 
                    
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Provide Your Email
                  {isThirdStepCompleted && <span style={{ marginLeft: '2%', fontSize: '32px', color: 'green' }} className="checkmark">&#10003;</span>}

                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <div style={{ height: '7%', width: '100%', padding: '5px' }}>
                 

                
                    
                    <button onClick={()=>handleSubmit} type="submit" className="btn btn-primary">Submit</button>

                  </div>
                </div>
              </div>
            </div>

          </div> */}
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FormComponent;

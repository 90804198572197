import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import "../../Globalcss/style.css";
import { BASE_URL } from "../../baseUrl";
import Swal from "sweetalert2";
import ContactUs from "./ContactUsBanner";
import InputMask from "react-input-mask";
import LoadingOverlay from "../Loader";
import { useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";
import Modal from "../Modal";
import Navbar from "../Navbar";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  first: Yup.string().required("First name is required"),
  last: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  confrm_email: Yup.string()
    .oneOf([Yup.ref("email"), null], "Email and confirm email must be same")
    .required("Confirm Email is required"),
  company: Yup.string().required("Company name is required"),
  phone: Yup.string().required("Phone is required"),
  help: Yup.string().required("Please let us know how we can help you"),
  myfile: Yup.mixed().required("A file is required"),
  how_did_you_hear: Yup.string().required(
    "Please let us know how you heard about us"
  ),
  average_monthly_volume: Yup.string().required(
    "Please provide average monthly volume"
  ),
  number_of_employees: Yup.number(
    "Number of employees should be in number form"
  ).required("Please provide number of employees"),
  recaptcha: Yup.string().required("Please complete the reCAPTCHA"),
  agree: Yup.boolean()
    .required("You must accept the Terms of Service to proceed")
    .oneOf([true], "You must accept the Terms of Service to proceed"),
});

const Index = () => {
  const [fileName, setFileName] = useState("");
  const fieldRefs = useRef({});
  const navigate = useNavigate();
  const recaptchaRef = useRef(null);
  const RECAPTCHA_SITE_KEY = "6Lfg3_cpAAAAAMGC__pvQVDpHDOJSRDcnrwA7lWQ";

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setErrors }
  ) => {
    const formData = new FormData();
    formData.append("first_name", values.first);
    formData.append("last_name", values.last);
    formData.append("email", values.email);
    formData.append("company_name", values.company);
    formData.append("phone", values.phone);
    formData.append("how_can_we_help_you", values.help);
    formData.append("statment_file", values.myfile);
    formData.append("how_did_you_hear", values.how_did_you_hear);
    formData.append("average_monthly_volume", values.average_monthly_volume);
    formData.append("number_of_employees", values.number_of_employees);
    formData.append("recaptcha", values.recaptcha);
    // formData.append('agree', values.agree);

    try {
      const response = await axios.post(BASE_URL + "user/addUser", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data) {
        localStorage.setItem("leadCapturForm", values.email);
        window.location.href = "/get-free-audit-analysis";

        resetForm();
        setFileName("");
      }
    } catch (error) {
      console.error("Error submitting form:", error);

      if (error.response.status === 400) {
        Swal.fire({
          icon: "error",
          title: error.response.data.message,
          text: "Email is already in use",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Server Error",
          text: "Something went wrong!",
        });
      }
    }
    setSubmitting(false);
  };

  return (
    <div>
      <Navbar />
      <ContactUs />
      <section className="contact_content">
        <div className="container py-5">
          <p className="hp fw-bold mb-2">
            For more information, please fill out the contact form below.
          </p>
          <Formik
            initialValues={{
              title: "Mr.",
              first: "",
              last: "",
              email: "",
              confrm_email: "",
              company: "",
              phone: "",
              number_of_employees: "",
              average_monthly_volume: "",
              help: "",
              myfile: null,
              how_did_you_hear: "",
              recaptcha: "",
              agree: false,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnMount
            validateOnChange={true}
          >
            {({
              setFieldValue,
              isSubmitting,
              validateForm,
              errors,
              values,
            }) => (
              <Form>
                <LoadingOverlay isLoading={isSubmitting} />
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div className="icon" id="icon1">
                      <Field
                        as="select"
                        name="title"
                        className="form-control"
                        innerRef={(el) => (fieldRefs.current["title"] = el)}
                      >
                        <option value="Mr.">Mr.</option>
                        <option value="Ms.">Ms.</option>
                        <option value="Mrs.">Mrs.</option>
                        <option value="Dr.">Dr.</option>
                        <option value="Prof.">Prof.</option>
                      </Field>
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <Field
                      type="text"
                      placeholder="First *"
                      name="first"
                      id="first"
                      className="form-control"
                      innerRef={(el) => (fieldRefs.current["first"] = el)}
                    />
                    <ErrorMessage
                      name="first"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <Field
                      type="text"
                      placeholder="Last *"
                      name="last"
                      id="last"
                      className="form-control"
                      innerRef={(el) => (fieldRefs.current["last"] = el)}
                    />
                    <ErrorMessage
                      name="last"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <Field
                      type="email"
                      placeholder="Enter Email *"
                      name="email"
                      id="email"
                      className="form-control"
                      innerRef={(el) => (fieldRefs.current["email"] = el)}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <Field
                      type="email"
                      placeholder="Confirm Email *"
                      name="confrm_email"
                      id="confrm_email"
                      className="form-control"
                      innerRef={(el) =>
                        (fieldRefs.current["confrm_email"] = el)
                      }
                    />
                    <ErrorMessage
                      name="confrm_email"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <Field
                      type="text"
                      placeholder="Company Name *"
                      name="company"
                      id="company"
                      className="form-control"
                      innerRef={(el) => (fieldRefs.current["company"] = el)}
                    />
                    <ErrorMessage
                      name="company"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <Field
                      name="phone"
                      render={({ field }) => (
                        <InputMask
                          {...field}
                          mask="(999) 999-9999"
                          placeholder="Phone *"
                          id="phone"
                          className="form-control"
                          innerRef={(el) => (fieldRefs.current["phone"] = el)}
                        />
                      )}
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <Field
                      type="number"
                      placeholder="Number Of Employees*"
                      name="number_of_employees"
                      id="number_of_employees"
                      className="form-control"
                      innerRef={(el) =>
                        (fieldRefs.current["number_of_employees"] = el)
                      }
                    />
                    <ErrorMessage
                      name="number_of_employees"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <Field
                      type="text"
                      placeholder="Average Monthly Volume"
                      name="average_monthly_volume"
                      id="average_monthly_volume"
                      className="form-control"
                      innerRef={(el) =>
                        (fieldRefs.current["average_monthly_volume"] = el)
                      }
                    />
                    <ErrorMessage
                      name="average_monthly_volume"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <Field
                      as="textarea"
                      placeholder="How can we help you?"
                      name="help"
                      id="help"
                      cols="30"
                      rows="5"
                      className="form-control"
                      innerRef={(el) => (fieldRefs.current["help"] = el)}
                    />
                    <ErrorMessage
                      name="help"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <p className="p1">
                      * Upload your statement here for a FREE, NO-OBLIGATION
                      analysis of your account. One of our Analysts will contact
                      you within 72 hours.
                    </p>
                    <div className="upload-btn-wrapper">
                      <span>Drop files here or </span>
                      <button className="btn" type="button">
                        Upload a file
                      </button>

                      <input
                        type="file"
                        className="fille"
                        name="myfile"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          setFieldValue("myfile", file);
                          setFileName(file ? file.name : "");
                        }}
                        innerRef={(el) => (fieldRefs.current["myfile"] = el)}
                      />

                      <div className="d-flex gap-3 align-items-center postion-relative">
                        {fileName && (
                          <p className="file-name mb-0">{fileName}</p>
                        )}
                        <div>
                          {values.myfile && (
                            <FaTimes
                              className="crossIcon fs-4"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setFieldValue("myfile", null);
                                setFileName("");
                              }}
                            />
                          )}
                        </div>
                      </div>

                      <ErrorMessage
                        name="myfile"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <p className="p2">
                      Accepted file types: pdf, xlsx, doc, jpg, gif, png, Max.
                      file size: 128 MB.
                    </p>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <Field
                      as="textarea"
                      placeholder="How did you hear about us?"
                      name="how_did_you_hear"
                      id="how_did_you_hear"
                      cols="30"
                      rows="3"
                      className="form-control"
                      innerRef={(el) =>
                        (fieldRefs.current["how_did_you_hear"] = el)
                      }
                    />
                    <ErrorMessage
                      name="how_did_you_hear"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-16 mt-3">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="agree"
                        className="form-check-input"
                        id="agree"
                        onChange={(e) =>
                          setFieldValue("agree", e.target.checked)
                        }
                        checked={values.agree}
                      />
                      <label
                        className="form-check-label agree_check_box"
                        for="flexCheckDefault"
                      >
                        By checking this box, I confirm that I have read,
                        understood, and agree to the{" "}
                        <span
                          className="terms_condtions_text font-weight-bold"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                        >
                          terms & condtions
                        </span>
                      </label>
                    </div>
                    <ErrorMessage
                      name="agree"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={RECAPTCHA_SITE_KEY}
                      onChange={(value) => setFieldValue("recaptcha", value)}
                      innerRef={(el) => (fieldRefs.current["recaptcha"] = el)}
                    />
                    <ErrorMessage
                      name="recaptcha"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="submt_div">
                    <button
                      type="submit"
                      id="submit_button_1"
                      className="gform_button button btn btn_sbmit"
                      disabled={isSubmitting}
                      onClick={() => {
                        validateForm().then((formErrors) => {
                          const firstError = Object.keys(formErrors)[0];
                          if (fieldRefs.current[firstError]) {
                            console.log(fieldRefs.current[firstError]);
                            fieldRefs.current[firstError].scrollIntoView({
                              behavior: "smooth",
                              block: "center",
                            });
                            fieldRefs.current[firstError].focus({
                              preventScroll: true,
                            });
                          }
                        });
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <Modal />
      </section>
    </div>
  );
};

export default Index;

import { useEffect } from 'react';


const RedirectToHtml = ({ target }) => {


  useEffect(() => {
    // Redirect to the target HTML file
    window.location.replace('/home.html');
    return null;
  }, []);

  return null;
};

export default RedirectToHtml;
import React from 'react';
import Logo_f from '../../assets/images/logo_f.png'
const Footer = () => {
  return (
    <footer className="footer fixed-bottom">
      <div className="container">
        <div className="top_ftr">
          <div className="row justify-content-center">
            <div className="d-flex flex-column align-items-center">
              <img
                className="ff text-center mb-2"
                src={Logo_f}
                alt=""
                srcSet=""
              />
              <div className="contenttf text-center">
                <p className="text-white">
                  <a
                    className="text-white text-decoration-none"
                    href="mailto:office@abc.com"
                  >
                    <i className="fas fa-envelope me-2"></i>
                    info@stopoverpayingfees.com
                  </a>
                </p>
                <p className="text-white">
                  © 2024 |
                  <a className="text-white" href="/privacy_policy.html">
                    Privacy Policy
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
